import { ComponentType, FC } from "react";
import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router";

import { useToken } from "@/hooks/authentication";

export const AuthenticatedRoute: FC<RouteProps> = ({ component, ...rest }) => {
  const [authToken, { isLoading }] = useToken();

  const Component = component as ComponentType<RouteComponentProps>;

  return (
    !isLoading && (
      <Route
        {...rest}
        render={(props) =>
          authToken ? <Component {...props} /> : <Redirect to="/sign-in" />
        }
      />
    )
  );
};
