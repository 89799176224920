import { Redirect, Route } from "react-router-dom";
import { IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import { AuthenticatedRoute } from "@/components/authenticated-route";
import { storage } from "@/utils/storage";

/* Pages */
import SignInPage from "@/pages/sign-in";
import SignUpSelectPage from "@/pages/sign-up";
import SignUpParentsPage from "@/pages/sign-up/parents";
import SignUpProfessionalsPage from "@/pages/sign-up/professionals";
import SignUpRegionalCenterParentsPage from "@/pages/sign-up/regional-center-parents";
import SignUpCompletePage from "@/pages/sign-up/complete";
import SongsPage from "@/pages/songs";
import SongPage from "@/pages/songs/[id]";
import GameplayPage from "@/pages/songs/[id]/play";
import VocabularyPage from "@/pages/vocabulary";
import SettingsPage from "@/pages/settings";
import AccountPage from "@/pages/account";
import HelpPage from "@/pages/help";
import ConfirmEmailPage from "./pages/confirm-email";
import ForgotPasswordPage from "./pages/forgot-password";
import ResetPasswordPage from "./pages/reset-password";

const Routes = () => {
  return (
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="/sign-in" component={SignInPage} />
        <Route exact path="/sign-up" component={SignUpSelectPage} />
        <Route exact path="/sign-up/parents" component={SignUpParentsPage} />
        <Route
          exact
          path="/sign-up/professionals"
          component={SignUpProfessionalsPage}
        />
        <Route
          exact
          path="/sign-up/regional-center-parents"
          component={SignUpRegionalCenterParentsPage}
        />
        <Route exact path="/confirm-email" component={ConfirmEmailPage} />
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <Route exact path="/reset-password" component={ResetPasswordPage} />

        <AuthenticatedRoute exact path="/">
          <Redirect to="/songs" />
        </AuthenticatedRoute>

        <Route exact path="/">
          <Redirect to="/sign-in" />
        </Route>

        <AuthenticatedRoute
          exact
          path="/sign-up/complete"
          component={SignUpCompletePage}
        />
        <AuthenticatedRoute exact path="/songs" component={SongsPage} />
        <AuthenticatedRoute exact path="/songs/:id" component={SongPage} />
        <AuthenticatedRoute
          exact
          path="/songs/:id/play"
          component={GameplayPage}
        />
        <AuthenticatedRoute
          exact
          path="/vocabulary"
          component={VocabularyPage}
        />
        <AuthenticatedRoute exact path="/settings" component={SettingsPage} />
        <AuthenticatedRoute exact path="/account" component={AccountPage} />
        <AuthenticatedRoute exact path="/help" component={HelpPage} />

        <Route
          exact
          path="/sign-out"
          render={() => {
            const signOut = async () => {
              await storage.clear();
            };

            signOut();

            return <Redirect to="/sign-in" />;
          }}
        />

        <Redirect to="/" />
      </IonRouterOutlet>
    </IonReactRouter>
  );
};

export default Routes;
